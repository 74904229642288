//ferpi sharqshunoslik///////////
export const environment = {
  otm: 'tkti',
  production: true,
  http_auth: 'JdI4VNf4SIG3VBgEWSUNF',
  otm_name: "Ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig: {
    // pvorey
    apiKey: 'AIzaSyCdXdbQrCOcdXI183VIcmetut_dCKG5dOU',
    authDomain: 'fjsti-c50ff.firebaseapp.com',
    projectId: 'fjsti-c50ff',
    storageBucket: 'fjsti-c50ff.appspot.com',
    messagingSenderId: '382705767044',
    appId: '1:382705767044:web:48527a1aeb7e8674c4e8d1',
    measurementId: 'G-L4NGYJKBMN',
  },
  http: {
    socket: {
      path: 'ws://ferpi.tsnqb.uz/socket.io',
    },
    editArea: {
      path: 'https://kpi.tkti.uz/api/system/area',
    },
    addArea: {
      path: 'https://kpi.tkti.uz/api/system/area',
    },
    deleteArea: {
      path: 'https://kpi.tkti.uz/api/system/area',
    },
    removeDepartment: {
      path: 'https://kpi.tkti.uz/api/system/department',
    },
    editDepartment: {
      path: 'https://kpi.tkti.uz/api/system/department',
    },
    getDepartmentAArea: {
      path: 'https://kpi.tkti.uz/api/system/getAll',
    },
    addDepartment: {
      path: 'https://kpi.tkti.uz/api/system/department',
    },
    userRegister: {
      path: 'https://kpi.tkti.uz/api/auth/registration',
    },
    recoverPass: {
      path: 'https://kpi.tkti.uz/api/mailing/send-mail',
    },
    allRoles: {
      path: 'https://kpi.tkti.uz/api/auth/login',
    },
    removeRole: {
      path: 'https://kpi.tkti.uz/api/auth/login',
    },
    setRole: {
      path: 'https://kpi.tkti.uz/api/auth/login',
    },
    removeTeacher: {
      path: 'https://kpi.tkti.uz/api/auth/login',
    },
    allUsers: {
      path: 'https://kpi.tkti.uz/api/users/all',
    },
    updateUser: {
      path: 'https://kpi.tkti.uz/api/auth/login',
    },
    deleteUser: {
      path: 'https://kpi.tkti.uz/api/auth/login',
    },
    login: {
      path: 'https://kpi.tkti.uz/api/auth/login',
    },
    autologin: {
      path: 'https://kpi.tkti.uz/api/auth/autologin',
    },
    get: {
      path: 'https://kpi.tkti.uz/db/2024/get_data.php',
      file: 'pvorey.txt',
    },
    delete_only_inform_file: {
      path: 'https://kpi.tkti.uz/db/2024/delete_only_inform_file.php',
    },
    getFieldsInform: {
      path: 'https://kpi.tkti.uz/db/2024/get_fields_inform.php',
    },
    setInc: {
      path: 'https://kpi.tkti.uz/db/2024/set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: 'https://kpi.tkti.uz/db/2024/del_data.php',
      file: 'pvorey.txt',
    },
    setIncDelUpd: {
      path: 'https://kpi.tkti.uz/db/2024/set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: 'https://kpi.tkti.uz/db/2024/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: 'https://kpi.tkti.uz/db/2024/google_scholar.php',
    },
    googleScholarCitedAll: {
      path: 'https://kpi.tkti.uz/db/2024/google_scholar_all.php',
    },
    setNewPvo: {
      path: 'https://kpi.tkti.uz/db/2024/set_new_pvo.php',
    },
    removePvo: {
      path: 'https://kpi.tkti.uz/db/2024/remove_pvo.php',
    },
    controlIndexStatus: {
      path: 'https://kpi.tkti.uz/db/2024/control_index_status.php',
    },
    createAnnounce: {
      path: 'https://kpi.tkti.uz/db/2024/create_announce.php',
    },
    getAnnounces: {
      path: 'https://kpi.tkti.uz/db/2024/get_announces.php',
    },
    removeAnnounce: {
      path: 'https://kpi.tkti.uz/db/2024/remove_announce.php',
    },
    editUserAccaunt: {
      path: 'https://kpi.tkti.uz/db/2024/edit_user_account.php',
    },
    createIndex: {
      path: 'https://kpi.tkti.uz/db/2024/create_index.php',
    },
  },
  tizim: [
    {
      fakultet: 'Neft va gaz texnologiyalari fakulteti',
      kafedralar: [
        'Fizika va energetika kafedrasi',
        'Sellyuloza va yog`ochsozlik texnologiyasi kafedrasi',
        'Neft va gazni qayta ishlash kimyoviy texnologiyasi kafedrasi',
        'Asosiy organik sintez texnologiyasi kafedrasi',
      ],
    },
    {
      fakultet: 'Oziq-ovqat va vinochilik texnologiyalari fakulteti',
      kafedralar: [
        "Enologiya va bijg'ish mahsulotlari texnologiyasi kafedrasi",
        'Biotexnologiya kafedrasi',
        'Oziq-ovqat mahsulotlari sifati va xavfsizligi kafedrasi',
        'Oziq-ovqat va parfyumeriya-kosmetika mahsulotlari texnologiyasi kafedrasi',
      ],
    },
    {
      fakultet: 'Kimyoviy injiniring fakulteti',
      kafedralar: [
        'Noorganik moddalar kimyoviy texnologiyasi kafedrasi',
        'Silikat materiallar va nodir, kamyob metallar texnologiyasi kafedrasi',
        'Texnologik mashina va jihozlar kafedrasi',
        'Yuqori molekulali birikmalar va plastmassalar texnologiyasi kafedrasi',
      ],
    },
    {
      fakultet: 'Sanoat muhandisligi va menejmenti fakulteti',
      kafedralar: [
        'Sanoat ekologiyasi kafedrasi',
        'Sаnоаt iqtisоdiyoti vа mеnеjmеnti kafedrasi',
        'Avtomatlashtirish va raqamli boshqaruv kafedrasi',
        'Muhandislik asoslari va mexanika kafedrasi',
      ],
    },
    {
      fakultet: 'Fakultetlararo kafedralar',
      kafedralar: [
        'Tillar kafedrasi',
        'Oliy matematika kafedrasi',
        'Kimyo kafedrasi',
        'Ijtimoiy-siyosiy fanlar kafedrasi',
      ],
    },
  ],
  grastud: 556,
  fakultet: [
    'Neft va gaz texnologiyalari fakulteti',
    'Oziq-ovqat va vinochilik texnologiyalari fakulteti',
    'Kimyoviy injiniring fakulteti',
    'Sanoat muhandisligi va menejmenti fakulteti',
    'Fakultetlararo kafedralar',
  ],
  kafedra: [
    'Fizika va energetika kafedrasi',
    'Sellyuloza va yog`ochsozlik texnologiyasi kafedrasi',
    'Neft va gazni qayta ishlash kimyoviy texnologiyasi kafedrasi',
    'Asosiy organik sintez texnologiyasi kafedrasi',
    "Enologiya va bijg'ish mahsulotlari texnologiyasi kafedrasi",
    'Biotexnologiya kafedrasi',
    'Oziq-ovqat mahsulotlari sifati va xavfsizligi kafedrasi',
    'Oziq-ovqat va parfyumeriya-kosmetika mahsulotlari texnologiyasi kafedrasi',
    'Noorganik moddalar kimyoviy texnologiyasi kafedrasi',
    'Silikat materiallar va nodir, kamyob metallar texnologiyasi kafedrasi',
    'Texnologik mashina va jihozlar kafedrasi',
    'Yuqori molekulali birikmalar va plastmassalar texnologiyasi kafedrasi',
    'Sanoat ekologiyasi kafedrasi',
    'Sаnоаt iqtisоdiyoti vа mеnеjmеnti kafedrasi',
    'Avtomatlashtirish va raqamli boshqaruv kafedrasi',
    'Muhandislik asoslari va mexanika kafedrasi',
    'Tillar kafedrasi',
    'Oliy matematika kafedrasi',
    'Kimyo kafedrasi',
    'Ijtimoiy-siyosiy fanlar kafedrasi',
  ],
};
